<div *ngIf="printData" #containerPrint id="containerPrint" class="container-print justify-center">
    <div #page id="page" class="page" size="A4">
        <div class="w-full" style='font-family:sans-serif;font-size:11px; vertical-align:top;'>
            <table cellpadding='0' cellspacing='5' border='0' width='100%'>
                <tr>
                    <td style='text-align:center; font-size:15px;'>e-Form : Q2O Process</td>
                </tr>
                <tr style='font-weight:bold;'>
                    <td style='border-bottom:2px solid black; text-align:left; font-size:13px;'>General Information</td>
                </tr>
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='99%'
                                style='font: 11px sans-serif; vertical-align:top;'>
                                <tr>
                                    <td style='width:10%; font-weight:bold;'>Requester Name:</td>
                                    <td style='width:23%;'>{{printData.general_Info.requester_name}}</td>
                                    <td style='width:10%; font-weight:bold;'>Company:</td>
                                    <td style='width:23%;'>{{printData.general_Info.requester_com_name}}</td>
                                    <td style='width:10%; font-weight:bold;'>Requester Form No.:</td>
                                    <td>{{printData.general_Info.request_no}}</td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'>Country:</td>
                                    <td>{{printData.general_Info.requester_country}}</td>
                                    <td style='font-weight:bold;'>Division:</td>
                                    <td>{{printData.general_Info.requester_division}}</td>
                                    <td style='font-weight:bold;'>Request Date:</td>
                                    <td>{{printData.general_Info.create_date}}</td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'>Department:</td>
                                    <td>{{printData.general_Info.requester_dept}}</td>
                                    <td style='font-weight:bold;'>Sale Office:</td>
                                    <td>{{printData.general_Info.sale_office_name}}</td>
                                    <td style='font-weight:bold;'>Request Status:</td>
                                    <td>{{printData.general_Info.description}}</td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'>Practice:</td>
                                    <td>{{printData.general_Info.trn_practice_name}}</td>
                                    <td style='font-weight:bold;'>Product Group:</td>
                                    <td>{{printData.general_Info.product_team}}</td>
                                    @if (printData.general_Info.is_project_sale == "X")
                                    {
                                    <td style='font-weight:bold;'>Project Code (ID):</td>
                                    <td>{{printData.general_Info.header_project_code}}</td>
                                    }
                                </tr>
                                @if (printData.general_Info.is_project_sale == "X")
                                {
                                <tr>
                                    <td style='font-weight:bold;'></td>
                                    <td></td>
                                    <td style='font-weight:bold;'></td>
                                    <td></td>
                                    <td style='font-weight:bold;'>Project Name:</td>
                                    <td>{{printData.general_Info.header_project_name}}</td>
                                </tr>
                                }
                                @if (printData.general_Info.is_foc == "X")
                                {
                                <tr>
                                    <td style='font-weight:bold;'></td>
                                    <td></td>
                                    <td style='font-weight:bold;'></td>
                                    <td></td>
                                    <td style='font-weight:bold;'>Cost Center:</td>
                                    <td>{{(printData.general_Info.cost_center_code ?? '') +
                                        (printData.general_Info.cost_center_code ?? '')}}</td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'></td>
                                    <td></td>
                                    <td style='font-weight:bold;'></td>
                                    <td></td>
                                    <td style='font-weight:bold;'>FOC Reason:</td>
                                    <td>{{printData.general_Info.order_reason_name}}</td>
                                </tr>
                                }
                                <tr>
                                    <td style='font-weight:bold;'>Remark:</td>
                                    <td colspan='4'>{{printData.general_Info.header_remark}}</td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
                @if (printData.is_to_stock !== 'X') {
                <tr style='font-weight:bold;'>
                    <td style='border-bottom:2px solid black; text-align:left; font-size:13px;'>Customer</td>
                </tr>
                @if (printData.customer) {
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='99%'
                                style='font: 11px sans-serif; vertical-align:top;'>
                                <tr>
                                    <td colspan='6' style='width:10%; font-weight:bold;'>Sold To Party:</td>
                                </tr>
                                <tr>
                                    <td style='width:10%; font-weight:bold;'>Name:</td>
                                    <td style='width:23%;'>
                                        {{printData.customer.sold_to.customer_code ?
                                        printData.customer.sold_to.customer_code + ':' +
                                        printData.customer.sold_to.customer_name : '-'}}
                                    </td>
                                    <td style='width:10%; font-weight:bold;'>Contact Person:</td>
                                    <td style='width:23%;'>{{printData.customer.sold_to.contact_person}}</td>
                                    <td style='width:10%; font-weight:bold;'>Address:</td>
                                    <td>{{printData.customer.sold_to.customer_address}}</td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'>Telephone:</td>
                                    <td>{{printData.customer.sold_to.tel_number}}</td>
                                    <td style='font-weight:bold;'>Fax:</td>
                                    <td>{{printData.customer.sold_to.fax_number}}</td>
                                    <td style='font-weight:bold;'></td>
                                    <td>
                                        {{trimEndDash((printData.customer.sold_to.customer_city ?? '') +
                                        (printData.customer.sold_to.customer_postal_code ?? ''))}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'>Mobile Phone:</td>
                                    <td>{{printData.customer.sold_to.mobile_number}}</td>
                                    <td style='font-weight:bold;'>Email:</td>
                                    <td>{{printData.customer.sold_to.email_address}}</td>
                                    <td style='font-weight:bold;'></td>
                                    <td>{{printData.customer.sold_to.country_name}}</td>
                                </tr>
                                <tr>
                                    <td colspan='6' style='padding-top:8px; width:10%; font-weight:bold;'>Ship To Party:
                                    </td>
                                </tr>
                                <tr>
                                    <td style='width:10%; font-weight:bold;'>Name:</td>
                                    <td style='width:23%;'>
                                        {{printData.customer.ship_to.customer_code ?
                                        printData.customer.ship_to.customer_code + ':' +
                                        printData.customer.ship_to.customer_name : '-'}}
                                    </td>
                                    <td style='width:10%; font-weight:bold;'>Contact Person:</td>
                                    <td style='width:23%;'>{{printData.customer.ship_to.contact_person}}</td>
                                    <td style='width:10%; font-weight:bold;'>Address:</td>
                                    <td>{{printData.customer.ship_to.customer_address}}</td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'>Telephone:</td>
                                    <td>{{printData.customer.ship_to.tel_number}}</td>
                                    <td style='font-weight:bold;'>Fax:</td>
                                    <td>{{printData.customer.ship_to.fax_number}}</td>
                                    <td style='font-weight:bold;'></td>
                                    <td>
                                        {{trimEndDash((printData.customer.ship_to.customer_city ?? '') +
                                        (printData.customer.ship_to.customer_postal_code ?? ''))}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'>Mobile Phone:</td>
                                    <td>{{printData.customer.ship_to.mobile_number}}</td>
                                    <td style='font-weight:bold;'>Email:</td>
                                    <td>{{printData.customer.ship_to.email_address}}</td>
                                    <td style='font-weight:bold;'></td>
                                    <td>{{printData.customer.ship_to.country_name}}</td>
                                </tr>
                                <tr>
                                    <td colspan='6' style='padding-top:8px; width:10%; font-weight:bold;'>Bill To Party:
                                    </td>
                                </tr>
                                <tr>
                                    <td style='width:10%; font-weight:bold;'>Name:</td>
                                    <td style='width:23%;'>
                                        {{printData.customer.bill_to.customer_code ?
                                        printData.customer.bill_to.customer_code + ':' +
                                        printData.customer.bill_to.customer_name : '-'}}
                                    </td>
                                    <td style='width:10%; font-weight:bold;'>Contact Person:</td>
                                    <td style='width:23%;'>{{printData.customer.bill_to.contact_person}}</td>
                                    <td style='width:10%; font-weight:bold;'>Address:</td>
                                    <td>{{printData.customer.bill_to.customer_address}}</td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'>Telephone:</td>
                                    <td>{{printData.customer.bill_to.tel_number}}</td>
                                    <td style='font-weight:bold;'>Fax:</td>
                                    <td>{{printData.customer.bill_to.fax_number}}</td>
                                    <td style='font-weight:bold;'></td>
                                    <td>
                                        {{trimEndDash((printData.customer.bill_to.customer_city ?? '') +
                                        (printData.customer.bill_to.customer_postal_code ?? ''))}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'>Mobile Phone:</td>
                                    <td>{{printData.customer.bill_to.mobile_number}}</td>
                                    <td style='font-weight:bold;'>Email:</td>
                                    <td>{{printData.customer.bill_to.email_address}}</td>
                                    <td style='font-weight:bold;'></td>
                                    <td>{{printData.customer.bill_to.country_name}}</td>
                                </tr>
                                <tr>
                                    <td colspan='6' style='padding-top:8px; width:10%; font-weight:bold;'>Payer To
                                        Party:</td>
                                </tr>
                                <tr>
                                    <td style='width:10%; font-weight:bold;'>Name:</td>
                                    <td style='width:23%;'>
                                        {{printData.customer.payer.customer_code ?
                                        printData.customer.payer.customer_code + ':' +
                                        printData.customer.payer.customer_name : '-'}}
                                    </td>
                                    <td style='width:10%; font-weight:bold;'>Contact Person:</td>
                                    <td style='width:23%;'>{{printData.customer.payer.contact_person}}</td>
                                    <td style='width:10%; font-weight:bold;'>Address:</td>
                                    <td>{{printData.customer.payer.customer_address}}</td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'>Telephone:</td>
                                    <td>{{printData.customer.payer.tel_number}}</td>
                                    <td style='font-weight:bold;'>Fax:</td>
                                    <td>{{printData.customer.payer.fax_number}}</td>
                                    <td style='font-weight:bold;'></td>
                                    <td>
                                        {{trimEndDash((printData.customer.payer.customer_city ?? '') +
                                        (printData.customer.payer.customer_postal_code ?? ''))}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'>Mobile Phone:</td>
                                    <td>{{printData.customer.payer.mobile_number}}</td>
                                    <td style='font-weight:bold;'>Email:</td>
                                    <td>{{printData.customer.payer.email_address}}</td>
                                    <td style='font-weight:bold;'></td>
                                    <td>{{printData.customer.payer.country_name}}</td>
                                </tr>
                                <tr>
                                    <td colspan='6' style='padding-top:8px; width:10%; font-weight:bold;'>Islamic Bank:
                                    </td>
                                </tr>
                                <tr>
                                    <td style='width:10%; font-weight:bold;'>Name:</td>
                                    <td style='width:23%;'>
                                        {{printData.customer.islamic.customer_code ?
                                        printData.customer.islamic.customer_code + ':' +
                                        printData.customer.islamic.customer_name : '-'}}
                                    </td>
                                    <td style='width:10%; font-weight:bold;'>Contact Person:</td>
                                    <td style='width:23%;'>{{printData.customer.islamic.contact_person}}</td>
                                    <td style='width:10%; font-weight:bold;'>Address:</td>
                                    <td>{{printData.customer.islamic.customer_address}}</td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'>Telephone:</td>
                                    <td>{{printData.customer.islamic.tel_number}}</td>
                                    <td style='font-weight:bold;'>Fax:</td>
                                    <td>{{printData.customer.islamic.fax_number}}</td>
                                    <td style='font-weight:bold;'></td>
                                    <td>
                                        {{trimEndDash((printData.customer.islamic.customer_city ?? '') +
                                        (printData.customer.islamic.customer_postal_code ?? ''))}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'>Mobile Phone:</td>
                                    <td>{{printData.customer.islamic.mobile_number}}</td>
                                    <td style='font-weight:bold;'>Email:</td>
                                    <td>{{printData.customer.islamic.email_address}}</td>
                                    <td style='font-weight:bold;'></td>
                                    <td>{{printData.customer.islamic.country_name}}</td>
                                </tr>
                                <tr>
                                    <td style='padding-top:8px; font-weight:bold;'>Payment Terms:</td>
                                    <td>{{printData.customer.pay_term}} : {{printData.customer.pay_term_text}}</td>
                                    <td style='font-weight:bold;'>Incoterms:</td>
                                    <td>{{printData.customer.inco_term}} : {{printData.customer.inco_term_place}}</td>
                                    <td style='font-weight:bold;'>Currency:</td>
                                    <td>{{printData.customer.currency}}</td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'>Ref. PO from Customer:</td>
                                    <td>{{printData.customer.po_ref_number}}</td>
                                    <td style='font-weight:bold;'>Customer PO Date:</td>
                                    <td>{{printData.customer.po_ref_date}}</td>
                                    <td style='font-weight:bold;'>Delivery Period/Laycan:</td>
                                    <td>{{printData.customer.delivery_from}} - {{printData.customer.delivery_to}}</td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold;'>SCGT Intercompany ref. No.:</td>
                                    <td>{{printData.customer.scgt_group_ref_no}}</td>
                                    <td style='font-weight:bold;'>Management Fee:</td>
                                    <td>{{printData.customer.managementfee}}</td>
                                    <td style='font-weight:bold;'></td>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
                }
                @else {
                <tr>
                    <td>-</td>
                </tr>
                }
                }
                <tr style='font-weight:bold;'>
                    <td style='border-bottom:2px solid black; text-align:left; font-size:13px;'>Supplier</td>
                </tr>
                @for (supplier of printData.supplier; track supplier; let i = $index) {
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='99%'
                                style='font: 11px sans-serif; vertical-align:top;'>
                                <tr>
                                    <td colspan='6' style='padding-top:8px; width:10%; font-weight:bold;'>
                                        Supplier {{i + 1}} :
                                    </td>
                                </tr>
                                <tr>
                                    <td style='width:10%; font-weight:bold;'>Name:</td>
                                    <td style='width:23%;'>
                                        {{supplier.vendor_code}} : {{supplier.vendor_name}}
                                    </td>
                                    <td style='width:10%; font-weight:bold;'>Contact Person:</td>
                                    <td style='width:23%;'>{{supplier.contact_person}}</td>
                                    <td style='width:10%; font-weight:bold;'>Address:</td>
                                    <td>{{supplier.vendor_address}}</td>
                                </tr>
                                <tr>
                                    <td style='width:10%; font-weight:bold;'>Telephone:</td>
                                    <td style='width:23%;'>{{supplier.tel_number}}</td>
                                    <td style='width:10%; font-weight:bold;'>Fax:</td>
                                    <td style='width:23%;'>{{supplier.fax_number}}</td>
                                    <td style='width:10%; font-weight:bold;'></td>
                                    <td>{{supplier.vendor_city}}{{supplier.vendor_postal_code}}</td>
                                </tr>
                                <tr>
                                    <td style='width:10%; font-weight:bold;'>Mobile Phone:</td>
                                    <td style='width:23%;'>{{supplier.mobile_number}}</td>
                                    <td style='width:10%; font-weight:bold;'>Email:</td>
                                    <td style='width:23%;'>{{supplier.email_address}}</td>
                                    <td style='width:10%; font-weight:bold;'></td>
                                    <td>{{supplier.country_name}}</td>
                                </tr>
                                <tr>
                                    <td style='width:10%; font-weight:bold;'>Payment Terms:</td>
                                    <td style='width:23%;'>
                                        {{supplier.pay_term}} : {{supplier.pay_term_text}}
                                    </td>
                                    <td style='width:10%; font-weight:bold;'>Incoterms:</td>
                                    <td style='width:23%;'>
                                        {{supplier.inco_term}} : {{supplier.inco_term_place}}
                                    </td>
                                    <td style='width:10%; font-weight:bold;'>Currency:</td>
                                    <td>{{supplier.currency}}</td>
                                </tr>
                                <tr>
                                    <td style='width:10%; font-weight:bold;'>Supplier Contract Ref. No.:</td>
                                    <td style='width:23%;'>{{supplier.contract_ref_no}}</td>
                                    <td style='width:10%; font-weight:bold;'></td>
                                    <td style='width:23%;'></td>
                                    <td style='width:10%; font-weight:bold;'>Delivery Period/Laycan:</td>
                                    <td>
                                        {{supplier.delivery_from}} - {{supplier.delivery_to}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
                }
                @empty {
                <tr>
                    <td>-</td>
                </tr>
                }
                <tr style='font-weight:bold;'>
                    <td style='border-bottom:2px solid black; text-align:left; font-size:13px;'>Material</td>
                </tr>
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='99%'
                                style='font: 11px sans-serif; vertical-align:top;'>
                                <tr>
                                    <td colspan='6' style='font-weight:bold;'>Description of Goods:</td>
                                </tr>
                                <tr>
                                    <td colspan='6'>{{printData.materail ? printData.materail.desc_of_goods : '-'}}</td>
                                </tr>
                                <tr>
                                    <td colspan='6' style='padding-top:8px; font-weight:bold;'>
                                        Material from {{'"Supplier"'}}
                                    </td>
                                </tr>
                                @if (!printData.materail || checkEmptyObject(printData.materail) || !printData.materail.direct || printData.materail.direct.length === 0) {
                                <tr>
                                    <td>-</td>
                                </tr>
                                }
                                @else {
                                <tr>
                                    <td>
                                        <table cellpadding='1' cellspacing='1' border='0' width='100%'
                                            style='font: 11px sans-serif; border-collapse:collapse;'>
                                            <tr style='font-weight:bold; text-align:center;'>
                                                <td rowspan='2' style='border:1px solid black;'>Item<br />No.</td>
                                                <td rowspan='2' style='border:1px solid black;'>Supplier</td>
                                                <td rowspan='2' style='border:1px solid black;'>
                                                    Material Code/ Description
                                                </td>
                                                <td rowspan='2' style='border:1px solid black;'>Plant</td>
                                                <td rowspan='2' style='border:1px solid black;'>
                                                    Storage<br />Location
                                                </td>
                                                <td rowspan='2' style='border:1px solid black;'>Quantity</td>
                                                <td colspan='2' style='border:1px solid black;'>Tolerance<br />(%)</td>
                                                <td rowspan='2' style='border:1px solid black;'>Buying Price</td>
                                                <td rowspan='2' style='border:1px solid black;'>Total Buying</td>
                                                <td rowspan='2' style='border:1px solid black;'>Product Type</td>
                                                <td rowspan='2' style='border:1px solid black;'>Tax Code</td>
                                                <td rowspan='2' style='border:1px solid black;'>
                                                    Down<br />Payment<br />Percent
                                                </td>
                                                <td rowspan='2' style='border:1px solid black;'>
                                                    Down<br />Payment<br />Due Date
                                                </td>
                                                <td rowspan='2' style='border:1px solid black;'>
                                                    Country of<br />Origin
                                                </td>
                                            </tr>
                                            <tr style='font-weight:bold; text-align:center;'>
                                                <td style='border:1px solid black;'>+</td>
                                                <td style='border:1px solid black;'>-</td>
                                            </tr>
                                            @for (direct of printData.materail.direct; track $index) {
                                            <tr>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{direct.item}}
                                                </td>
                                                <td style='border:1px solid black;'>{{direct.supplier_name}}</td>
                                                <td style='border:1px solid black;'>{{direct.material_code_desc}}</td>
                                                <td style='border:1px solid black;'>{{direct.plant_name}}</td>
                                                <td style='border:1px solid black;'>{{direct.storage_location}}</td>
                                                <td style='text-align:right; border:1px solid black;'>
                                                    {{direct.quantity}}
                                                </td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{direct.tolerance_up}}
                                                </td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{direct.tolerance_up}}
                                                </td>
                                                <td style='text-align:right; border:1px solid black;'>
                                                    {{direct.buying_price}}
                                                </td>
                                                <td style='text-align:right; border:1px solid black;'>
                                                    {{direct.total_price}}
                                                </td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{direct.product_type}}
                                                </td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{direct.tax_code}}
                                                </td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{direct.down_payment_percent}}
                                                </td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{direct.down_payment_due_date}}
                                                </td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{direct.country_origin}}
                                                </td>
                                            </tr>
                                            }
                                        </table>
                                    </td>
                                </tr>
                                }
                                <tr>
                                    <td colspan='6' style='padding-top:8px; font-weight:bold;'>
                                        Material from {{'"Stock, Take Position"'}}
                                    </td>
                                </tr>
                                @if (printData.is_to_stock !== "X") {
                                @if (!printData.materail || checkEmptyObject(printData.materail) || !printData.materail.from_stock || printData.materail.from_stock.length === 0) {
                                <tr>
                                    <td>-</td>
                                </tr>
                                }
                                @else {
                                <tr>
                                    <td>
                                        <table cellpadding='1' cellspacing='1' border='0' width='100%'
                                            style='font: 11px sans-serif; border-collapse:collapse;'>
                                            <tr style='font-weight:bold; text-align:center;'>
                                                <td rowspan='2' style='border:1px solid black;'>Item<br />No.</td>
                                                <td rowspan='2' style='border:1px solid black;'>
                                                    Material Code/ Description
                                                </td>
                                                <td rowspan='2' style='border:1px solid black;'>Plant</td>
                                                <td rowspan='2' style='border:1px solid black;'>Ref. PO / Batch</td>
                                                <td rowspan='2' style='border:1px solid black;'>Quantity</td>
                                                <td colspan='2' style='border:1px solid black;'>Tolerance<br />(%)</td>
                                                <td rowspan='2' style='border:1px solid black;'>
                                                    Cost of Goods /<br />Unit
                                                </td>
                                                <td rowspan='2' style='border:1px solid black;'>
                                                    Total Cost of Goods<br />(Com. Cur.)
                                                </td>
                                                <td rowspan='2' style='border:1px solid black;'>Product Type</td>
                                                <td rowspan='2' style='border:1px solid black;'>
                                                    Country of<br />Origin
                                                </td>
                                            </tr>
                                            <tr style='font-weight:bold; text-align:center;'>
                                                <td style='border:1px solid black;'>+</td>
                                                <td style='border:1px solid black;'>-</td>
                                            </tr>
                                            @for (fromStock of printData.materail.from_stock; track $index) {
                                            <tr>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{fromStock.item}}
                                                </td>
                                                <td style='border:1px solid black;'>{{fromStock.material_code_desc}}
                                                </td>
                                                <td style='border:1px solid black;'>{{fromStock.plant_name}}</td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    <span [innerHTML]="fromStock.batchtext"></span>
                                                </td>
                                                <td style='text-align:right; border:1px solid black;'>
                                                    {{fromStock.quantity}}
                                                </td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{fromStock.tolerance_down}}
                                                </td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{fromStock.tolerance_up}}
                                                </td>
                                                <td style='text-align:right; border:1px solid black;'>
                                                    {{fromStock.cost_of_goods_unit}}
                                                </td>
                                                <td style='text-align:right; border:1px solid black;'>
                                                    {{fromStock.total_cost_of_goods}}
                                                </td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{fromStock.product_type}}
                                                </td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{fromStock.country_origin}}
                                                </td>
                                            </tr>
                                            }
                                        </table>
                                    </td>
                                </tr>
                                }
                                }
                            </table>
                        </div>
                    </td>
                </tr>
                <tr style='font-weight:bold;'>
                    <td style='border-bottom:2px solid black; text-align:left; font-size:13px;'>Shipment</td>
                </tr>
                @if (printData.shipment.shipment_mode_code === '-') {
                <tr>
                    <td>-</td>
                </tr>
                }
                @else {
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='99%'
                                style='font: 11px sans-serif;'>
                                <tr>
                                    <td colspan='4'>
                                        <table cellpadding='1' cellspacing='1' border='0' width='20%'
                                            style='font: 11px sans-serif; border-collapse:collapse;'>
                                            <tr style='font-weight:bold; text-align:center;'>
                                                <td style='border:1px solid black;'>No</td>
                                                <td style='border:1px solid black;'>Number of Shipment</td>
                                            </tr>
                                            @for (ship of printData.shipment.shipment; track $index) {
                                            <tr>
                                                <td style='border:1px solid black; text-align:center;'>{{ship.row_num}}
                                                </td>
                                                <td style='border:1px solid black; text-align:right;'>
                                                    {{ship.shipment_number}}</td>
                                            </tr>
                                            }
                                        </table>
                                    </td>
                                </tr>
                                <tr style='padding-top:8px;'>
                                    <td style='width:10%; font-weight:bold;'>Mode of Shipment:</td>
                                    <td style='width:40%;'>{{printData.shipment.shipment_mode_name}}</td>
                                    <td style='width:10%; font-weight:bold;'>Partial Shipment:</td>
                                    <td style='width:40%;'>{{printData.shipment.partail}}</td>
                                </tr>
                                @if (printData.shipment.shipment_mode_code == "SH03" || printData.shipment.shipment_mode_code ==
                                "SH04") {
                                @if (printData.shipment.loading_place !== null && printData.shipment.discharging_place
                                !== null) {
                                <tr>
                                    <td style='font-weight:bold;'>Loading Place:</td>
                                    <td>{{printData.shipment.loading_place}}, {{printData.shipment.loading_country}}
                                    </td>
                                    <td style='font-weight:bold;'>Unloading Place:</td>
                                    <td>{{printData.shipment.discharging_place}},
                                        {{printData.shipment.unloading_country}}</td>
                                </tr>
                                }
                                }
                                @if (printData.shipment.shipment_mode_code == "SH01" || printData.shipment.shipment_mode_code ==
                                "SH02" || printData.shipment.shipment_mode_code == "SH05")
                                {
                                @if (printData.shipment.loading_port != null && printData.shipment.discharging_port !=
                                null)
                                {
                                <tr>
                                    <td style='font-weight:bold;'>Loading Port:</td>
                                    <td>{{printData.shipment.loading_port}}, {{printData.shipment.loading_country}}</td>
                                    <td style='font-weight:bold;'>Discharging Port:</td>
                                    <td>{{printData.shipment.discharging_port}},
                                        {{printData.shipment.discharging_country}}</td>
                                </tr>
                                }
                                }
                                @if (printData.shipment.shipment_mode_code != "SH02" && printData.shipment.shipment_mode_code !=
                                "SH03")
                                {
                                @if (printData.shipment.shipment_mode_code == "SH01")
                                {
                                <tr>
                                    <td colspan='4' style='padding-top:8px; font-weight:bold;'>Container Information:
                                    </td>
                                </tr>
                                @if (!printData.shipment || checkEmptyObject(printData.shipment) || !printData.shipment.shipContainer || printData.shipment.shipContainer.length == 0) {
                                <tr>
                                    <td>-</td>
                                </tr>
                                }
                                @else {
                                <tr>
                                    <td colspan='4'>
                                        <table cellpadding='1' cellspacing='1' border='0' width='50%'
                                            style='font: 11px sans-serif; border-collapse:collapse;'>
                                            <tr style='font-weight:bold; text-align:center;'>
                                                <td style='border:1px solid black;'>No</td>
                                                <td style='border:1px solid black;'>Container Type</td>
                                                <td style='border:1px solid black;'>Number of Container</td>
                                            </tr>

                                            @for (shipContainer of printData.shipment.shipContainer; track $index) {
                                            <tr>
                                                <td style='border:1px solid black; text-align:center;'>
                                                    {{shipContainer.row_num}}</td>
                                                <td style='border:1px solid black;'>
                                                    {{shipContainer.container_type_name}}</td>
                                                <td style='border:1px solid black; text-align:right;'>
                                                    {{shipContainer.container_number}}</td>
                                            </tr>
                                            }
                                        </table>
                                    </td>
                                </tr>
                                }
                                }
                                }
                                <tr>
                                    <td colspan='4' style='padding-top:8px; font-weight:bold;'>Inland Information:</td>
                                </tr>
                                @if (!printData.shipment || checkEmptyObject(printData.shipment) || !printData.shipment.shipTruck || printData.shipment.shipTruck.length == 0) {
                                <tr>
                                    <td>-</td>
                                </tr>
                                }
                                @else {
                                <tr>
                                    <td colspan='4'>
                                        <table cellpadding='1' cellspacing='1' border='0' width='50%'
                                            style='font: 11px sans-serif; border-collapse:collapse;'>
                                            <tr style='font-weight:bold; text-align:center;'>
                                                <td style='border:1px solid black;'>No</td>
                                                <td style='border:1px solid black;'>Truck Type</td>
                                                <td style='border:1px solid black;'>Side</td>
                                                <td style='border:1px solid black;'>No. of Truck</td>
                                            </tr>

                                            @for (shipTruck of printData.shipment.shipTruck; track $index)
                                            {
                                            <tr>
                                                <td style='border:1px solid black; text-align:center;'>
                                                    {{shipTruck.row_num}}</td>
                                                <td style='border:1px solid black;'>{{shipTruck.truck_type_name}}</td>
                                                <td style='border:1px solid black;'>{{shipTruck.truck_side}}</td>
                                                <td style='border:1px solid black; text-align:right;'>
                                                    {{shipTruck.truck_number}}</td>
                                            </tr>
                                            }
                                        </table>
                                    </td>
                                </tr>
                                }
                                <tr>
                                    <td colspan='4' style='padding-top:8px; font-weight:bold;'>Remark: <span
                                            style='color:red; font-weight:none;'>* Information in REMARK is for
                                            communicate to operation team</span></td>
                                </tr>
                                <tr>
                                    <td colspan='4'>{{printData.shipment.remark}}</td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
                }
                <tr style='font-weight:bold;'>
                    <td style='border-bottom:2px solid black; text-align:left; font-size:13px;'>Expense</td>
                </tr>
                <tr>
                    <td colspan='4' style='font:11px sans-serif; font-weight:bold;'>Purchasing Expense:</td>
                </tr>
                @if (!printData.expense || checkEmptyObject(printData.expense) || !printData.expense.purchasing || printData.expense.purchasing.length == 0) {
                <tr>
                    <td>-</td>
                </tr>
                }
                @else {
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='100%'
                                style='font: 11px sans-serif; border-collapse:collapse;'>
                                <tr style='font-weight:bold; text-align:center;'>
                                    <td style='border:1px solid black;'>Expense</td>
                                    <td style='border:1px solid black;'>Vendor</td>
                                    <td style='border:1px solid black;'>Calculation Type</td>
                                    <td style='border:1px solid black;'>Amount</td>
                                    <td style='border:1px solid black;'>Total Amount</td>
                                </tr>

                                @for (purchasing of printData.expense.purchasing; track $index)
                                {
                                <tr>
                                    <td style='border:1px solid black;'>{{purchasing.exp_type}}</td>
                                    <td style='border:1px solid black;'>{{purchasing.vendor}}</td>
                                    <td style='border:1px solid black;'>{{purchasing.calculation_type}}</td>
                                    <td style='border:1px solid black; text-align:right;'>{{purchasing.amount}}
                                        {{purchasing.exp_cur}}</td>
                                    <td style='border:1px solid black; text-align:right;'>{{purchasing.total_amount}}
                                        {{purchasing.exp_cur}}</td>
                                </tr>
                                }
                            </table>
                        </div>
                    </td>
                </tr>
                }
                @if (printData.is_to_stock !== "X") {
                <tr>
                    <td colspan='4' style='padding-top:8px; font:11px sans-serif; font-weight:bold;'>Selling Expense:
                    </td>
                </tr>
                @if (!printData.expense || checkEmptyObject(printData.expense) || !printData.expense.selling || printData.expense.selling.length == 0) {
                <tr>
                    <td>-</td>
                </tr>
                }
                @else {
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='100%'
                                style='font: 11px sans-serif; border-collapse:collapse;'>
                                <tr style='font-weight:bold; text-align:center;'>
                                    <td style='border:1px solid black;'>Expense</td>
                                    <td style='border:1px solid black;'>Vendor</td>
                                    <td style='border:1px solid black;'>Calculation Type</td>
                                    <td style='border:1px solid black;'>Amount</td>
                                    <td style='border:1px solid black;'>Total Amount</td>
                                </tr>
                                @for (selling of printData.expense.selling; track $index)
                                {
                                <tr>
                                    <td style='border:1px solid black;'>{{selling.exp_type}}</td>
                                    <td style='border:1px solid black;'>{{selling.vendor}}</td>
                                    <td style='border:1px solid black;'>{{selling.calculation_type}}</td>
                                    <td style='border:1px solid black; text-align:right;'>{{selling.amount}}
                                        {{selling.exp_cur}}</td>
                                    <td style='border:1px solid black; text-align:right;'>{{selling.total_amount}}
                                        {{selling.exp_cur}}</td>
                                </tr>
                                }
                            </table>
                        </div>
                    </td>
                </tr>
                }
                }
                <tr style='font-weight:bold;'>
                    <td style='border-bottom:2px solid black; text-align:left; font-size:13px;'>Selling Price</td>
                </tr>
                @if (!printData.selling_price || checkEmptyObject(printData.selling_price) || !printData.selling_price.selling_price || printData.selling_price.selling_price.length === 0) {
                <tr>
                    <td>-</td>
                </tr>
                }
                @else
                {
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='100%'>
                                <tr>
                                    <td>
                                        <table cellpadding='1' cellspacing='1' border='0' width='100%'
                                            style='font: 11px sans-serif; border-collapse:collapse;'>
                                            <tr style='font-weight:bold; text-align:center;'>
                                                <td rowspan='2' style='border:1px solid black;'>Item<br />No.</td>
                                                <td rowspan='2' style='border:1px solid black;'>Material
                                                    Code/<br />Description</td>
                                                <td rowspan='2' style='border:1px solid black;'>
                                                    BOM<br />Material<br />Item No.</td>
                                                <td rowspan='2' style='border:1px solid black;'>Plant</td>
                                                <td rowspan='2' style='border:1px solid black;'>Stor.<br />Loc.</td>
                                                <td rowspan='2' style='border:1px solid black;'>Privilege</td>
                                                <td rowspan='2' style='border:1px solid black;'>Quantity</td>
                                                <td colspan='2' style='border:1px solid black;'>Tolerance<br />(%)</td>
                                                <td rowspan='2' style='border:1px solid black;'>Selling Price
                                                    /<br />Unit</td>
                                                <td rowspan='2' style='border:1px solid black;'>Total Selling Price</td>
                                                <td rowspan='2' style='border:1px solid black;'>Product Type</td>
                                                <td rowspan='2' style='border:1px solid black;'>Price List</td>
                                            </tr>
                                            <tr style='font-weight:bold; text-align:center;'>
                                                <td style='border:1px solid black;'>+</td>
                                                <td style='border:1px solid black;'>-</td>
                                            </tr>
                                            @for (selling of printData.selling_price.selling_price; track $index)
                                            {
                                            <tr>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{selling.sel_item_no}}</td>
                                                <td style='border:1px solid black;'>{{selling.materail_code}} :
                                                    {{selling.materail_desc}}</td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{selling.bom_header_pur_item_no}}</td>
                                                <td style='border:1px solid black;'>{{selling.plant_name}}</td>
                                                <td style='border:1px solid black;'>{{selling.storage_location}}</td>
                                                <td style='border:1px solid black;'>{{selling.privilege}}</td>
                                                <td style='text-align:right; border:1px solid black;'>
                                                    {{selling.sel_qty}} {{selling.sel_unit}}</td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{selling.sel_tolerance_up_percent}}</td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{selling.sel_tolerance_down_percent}}</td>
                                                <td style='text-align:right; border:1px solid black;'>
                                                    {{selling.selling_price_per_unit_sel_cur}}</td>
                                                <td style='text-align:right; border:1px solid black;'>
                                                    {{selling.total_selling_price_sel_cur}}</td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{selling.sel_product_type}}</td>
                                                <td style='text-align:center; border:1px solid black;'>
                                                    {{selling.is_price_list}}</td>
                                            </tr>
                                            }
                                            <tr style='font-weight:bold;'>
                                                <td colspan='10' style='text-align:right; border:1px solid black;'>Net
                                                    Sale: </td>
                                                <td style='border:1px solid black; text-align:right'>
                                                    {{printData.selling_price.total_selling_price_sel_cur}}
                                                    {{printData.selling_price.sel_cur}}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan='6' style='padding-top:8px; font-weight:bold;'>Brand & Packaging</td>
                                </tr>
                                @if (!printData.selling_price || checkEmptyObject(printData.selling_price) || !printData.selling_price.brand_packaging || printData.selling_price.brand_packaging.length === 0) {
                                <tr>
                                    <td>-</td>
                                </tr>
                                }
                                @else {
                                <tr>
                                    <td>
                                        <table cellpadding='1' cellspacing='1' border='0' width='100%'
                                            style='font: 11px sans-serif; border-collapse:collapse;'>
                                            <tr style='font-weight:bold; text-align:center;'>
                                                <td style='border:1px solid black;'>Material Code</td>
                                                <td style='border:1px solid black;'>Material Description</td>
                                                <td style='border:1px solid black;'>Brand</td>
                                                <td style='border:1px solid black;'>Packaging</td>
                                            </tr>

                                            @for (brand of printData.selling_price.brand_packaging; track $index) {
                                            <tr>
                                                <td style='border:1px solid black;'>{{brand.materail_code}}</td>
                                                <td style='border:1px solid black;'>{{brand.materail_desc}}</td>
                                                <td style='border:1px solid black;'>{{brand.brand}}</td>
                                                <td style='border:1px solid black;'>{{brand.packing}}</td>
                                            </tr>
                                            }

                                        </table>
                                    </td>
                                </tr>
                                @if(printData.selling_price.shipping_mark)
                                {
                                <tr>
                                    <td colspan='6' style='padding-top:8px; font-weight:bold;'>Shipping Mark</td>
                                </tr>
                                <tr>
                                    <td colspan='6'>{{printData.selling_price.shipping_mark.shipping_mark}}</td>
                                </tr>
                                <tr>
                                    <td colspan='6' style='font-weight:bold;'>Packing Instruction</td>
                                </tr>
                                <tr>
                                    <td colspan='6'>{{printData.selling_price.shipping_mark.packing_instruction}}</td>
                                </tr>
                                }
                                }
                            </table>
                        </div>
                    </td>
                </tr>
                }
                @if (printData.is_to_stock !== 'X') {
                <tr style='font-weight:bold;'>
                    <td style='border-bottom:2px solid black; text-align:left; font-size:13px;'>Allocate Packaging Cost
                    </td>
                </tr>
                @if (!printData.allocate_packaging || printData.allocate_packaging.length === 0) {
                <tr>
                    <td>-</td>
                </tr>
                }
                @else {
                @for (item of printData.allocate_packaging; track $index) {
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='100%'
                                style='padding-top:8px; font: 11px sans-serif; border-collapse:collapse;'>
                                <tr>
                                    <td style='font-weight:bold; width:10%'>Material Code:</td>
                                    <td style='width:23%'>{{item.materail_code}}</td>
                                    <td style='font-weight:bold; width:10%'>Plant:</td>
                                    <td style='width:23%'>{{item.plant_name}}</td>
                                    <td style='font-weight:bold; width:10%'>Storage Location</td>
                                    <td>{{item.storage_location}}</td>
                                </tr>
                                <tr>
                                    <td style='font-weight:bold; width:10%'>Packaging Quantity:</td>
                                    <td style='width:23%'>{{item.sel_qty}} {{item.sel_unit}}</td>
                                    <td style='font-weight:bold; width:10%'>Total Cost:</td>
                                    <td style='width:23%'>{{item.total_buying_price_pur_cur}} {{item.pur_cur}}</td>
                                    <td style='font-weight:bold; width:10%'></td>
                                    <td></td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table cellpadding='1' cellspacing='1' border='0' width='100%'
                            style='font: 11px sans-serif; border-collapse:collapse;'>
                            <tr style='text-align:center; font-weight:bold'>
                                <td style='border:1px solid black;'>Item<br />No.</td>
                                <td style='border:1px solid black;'>Material Code/<br />Description</td>
                                <td style='border:1px solid black;'>Plant</td>
                                <td style='border:1px solid black;'>Storage<br />Location</td>
                                <td style='border:1px solid black;'>Selling<br />Quantity</td>
                                <td style='border:1px solid black;'>Product<br />Type</td>
                                <td style='border:1px solid black;'>Packaging<br />Quantity</td>
                                <td style='border:1px solid black;'>Total<br />Selling Price</td>
                                <td style='border:1px solid black;'>Packaging<br />Amount</td>
                            </tr>
                            @for (itemIn of item.allocate_packaginf_item; track $index) {
                            <tr>
                                <td style='text-align:center; border:1px solid black;'>{{itemIn.sel_item_no}}</td>
                                <td style='border:1px solid black;'>{{itemIn.materail_code}} : {{itemIn.materail_desc}}
                                </td>
                                <td style='border:1px solid black;'>{{itemIn.plant_name}}</td>
                                <td style='border:1px solid black;'>{{itemIn.storage_location}}</td>
                                <td style='text-align:right; border:1px solid black;'>{{itemIn.sel_qty}}
                                    {{itemIn.sel_unit}}</td>
                                <td style='text-align:center; border:1px solid black;'>{{itemIn.sel_product_type}}</td>
                                <td style='text-align:right; border:1px solid black;'>{{itemIn.packaging_qty}}
                                    {{itemIn.pack_unit}}
                                </td>
                                <td style='text-align:right; border:1px solid black;'>
                                    {{itemIn.total_selling_price_sel_cur}}
                                    {{itemIn.pack_cur}}</td>
                                <td style='text-align:right; border:1px solid black;'>{{itemIn.packaging_amount}}
                                    {{itemIn.pack_cur}}</td>
                            </tr>
                            }
                            <tr>
                                <td colspan='6' style='border:1px solid black; text-align:right; font-weight:bold;'>
                                    Total: </td>
                                <td style='border:1px solid black; text-align:right; font-weight:bold;'>{{item.pur_qty}}
                                    {{item.pack_unit}}</td>
                                <td style='border:1px solid black; text-align:right; font-weight:bold;'>Total: </td>
                                <td style='border:1px solid black; text-align:right; font-weight:bold;'>
                                    {{item.total_buying_price_pur_cur}} {{item.pur_cur}}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                }
                }
                }
                <tr style='font-weight:bold;'>
                    <td style='border-bottom:2px solid black; text-align:left; font-size:13px;'>Cost of Goods</td>
                </tr>
                @if (!printData.cost_of_goods || printData.cost_of_goods.length === 0) {
                <tr>
                    <td>-</td>
                </tr>
                }
                @else {
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='100%'
                                style='font: 11px sans-serif; border-collapse:collapse;'>
                                <tr style='font-weight:bold; text-align:center;'>
                                    <td style='border:1px solid black;'>Material Code</td>
                                    <td style='border:1px solid black;'>Material Description</td>
                                    <td style='border:1px solid black;'>Plant</td>
                                    <td style='border:1px solid black;'>Storage<br />Location</td>
                                    <td style='border:1px solid black;'>Quantity</td>
                                    <td style='border:1px solid black;'>Buying Price/Unit</td>
                                    <td style='border:1px solid black;'>Expense/Unit</td>
                                    <td style='border:1px solid black;'>Total Cost of<br />Goods/Unit</td>
                                </tr>
                                @for (item of printData.cost_of_goods; track $index) {
                                <tr>
                                    <td style='border:1px solid black;'>{{item.materail_code}}</td>
                                    <td style='border:1px solid black;'>{{item.materail_desc}}</td>
                                    <td style='border:1px solid black;'>{{item.plant_name}}</td>
                                    <td style='border:1px solid black;'>{{item.storage_location}}</td>
                                    <td style='text-align:right; border:1px solid black;'>{{item.pur_qty}}
                                        {{item.pur_unit}}</td>
                                    <td style='text-align:right; border:1px solid black;'>
                                        {{item.buying_price_per_unit_pur_com}} {{item.pur_cur}}</td>
                                    <td style='text-align:right; border:1px solid black;'>
                                        {{item.expense_per_unit_pur_com}} {{item.pur_cur}}</td>
                                    <td style='text-align:right; border:1px solid black;'>
                                        {{item.total_cogs_per_unit_pur_com}} {{item.pur_cur}}</td>
                                </tr>
                                }
                            </table>
                        </div>
                    </td>
                </tr>
                }
                <tr style='font-weight:bold;'>
                    <td style='border-bottom:2px solid black; text-align:left; font-size:13px;'>Commission</td>
                </tr>
                @if (printData.commission) {
                @if (checkEmptyObject(printData.commission) || !printData.commission.rep || printData.commission.rep.length === 0) {
                <tr>
                    <td>-</td>
                </tr>
                }
                @else {
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='100%'
                                style='font: 11px sans-serif; border-collapse:collapse;'>
                                <tr style='font-weight:bold; text-align:center;'>
                                    <td style='border:1px solid black;'>Commission To<br />SCG Company</td>
                                    <td style='border:1px solid black;'>Rep. Office</td>
                                    <td style='border:1px solid black;'>Calculation Type</td>
                                    <td style='border:1px solid black;'>Amount</td>
                                    <td style='border:1px solid black;'>Total Amount</td>
                                </tr>
                                @for (item of printData.commission.rep; track $index) {
                                <tr>
                                    <td style='border:1px solid black;'>{{item.vendor_code}} : {{item.vendor_name}}</td>
                                    <td style='border:1px solid black;'>{{item.ref_office}}</td>
                                    <td style='border:1px solid black;'>{{item.calculation}}</td>
                                    <td style='text-align:right; border:1px solid black;'>{{item.amount}}
                                        {{item.amount_currency}}</td>
                                    <td style='text-align:right; border:1px solid black;'>{{item.total_amount}}
                                        {{item.amount_currency}}</td>
                                </tr>
                                }
                            </table>
                        </div>
                    </td>
                </tr>
                }
                @if (checkEmptyObject(printData.commission) || !printData.commission.other || printData.commission.other.length === 0) {
                <tr>
                    <td>-</td>
                </tr>
                }
                @else {
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='100%'
                                style='padding-top:8px; font: 11px sans-serif; border-collapse:collapse;'>
                                <tr style='font-weight:bold; text-align:center;'>
                                    <td style='border:1px solid black;'>Commission To Other</td>
                                    <td style='border:1px solid black;'>Calculation Type</td>
                                    <td style='border:1px solid black;'>Amount</td>
                                    <td style='border:1px solid black;'>Total Amount</td>
                                </tr>
                                @for (item of printData.commission.other; track $index) {
                                <tr>
                                    <td style='border:1px solid black;'>{{item.vendor_code}} : {{item.vendor_name}}</td>
                                    <td style='border:1px solid black;'>{{item.calculation}}</td>
                                    <td style='text-align:right; border:1px solid black;'>{{item.amount}}
                                        {{item.amount_currency}}</td>
                                    <td style='text-align:right; border:1px solid black;'>{{item.total_amount}}
                                        {{item.amount_currency}}</td>
                                </tr>
                                }
                            </table>
                        </div>
                    </td>
                </tr>
                }
                }
                @else {
                <tr>
                    <td>-</td>
                </tr>
                }
                @if (printData.is_to_stock != "X") {
                <tr>
                    <td>
                        <table cellpadding='1' cellspacing='1' border='0' width='99%' style='font: 11px sans-serif;'>
                            <tr>
                                <td style='width:10%; font-weight:bold;'>Profit on Net Sale:</td>
                                <td>{{printData.commission.profit_on_net_sale_amount}}
                                </td>
                            </tr>
                            <tr>
                                <td style='width:10%; font-weight:bold;'>Margin:</td>
                                <td>{{printData.commission.profit_on_net_sale_percent_margin}}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                }
                <tr style='font-weight:bold;'>
                    <td style='border-bottom:2px solid black; text-align:left; font-size:13px;'>Collection Commission From</td>
                </tr>
                @if (printData.collection_commission_from) {
                    <tr>
                        <td>
                            <div class="flex justify-center">
                                <table cellpadding='1' cellspacing='1' border='0' width='99%'
                                    style='font: 11px sans-serif; vertical-align:top;'>
                                    <tr>
                                        <td style='width:10%; font-weight:bold;'>Subject:</td>
                                        <td style='width:23%;'>{{printData.collection_commission_from.subject}}</td>
                                        <td style='width:10%; font-weight:bold;'>SO No.:</td>
                                    </tr>
                                    <tr>
                                        <td style='width:10%; font-weight:bold;'>Commission:</td>
                                        <td style='width:23%;'>{{printData.collection_commission_from.commission}}</td>
                                        <td style='width:10%; font-weight:bold;'>Sold To:</td>
                                        <td style='width:23%;'>{{printData.collection_commission_from.sold_to}}</td>
                                    </tr>
                                    <tr>
                                        <td style='width:10%; font-weight:bold;'>Payer:</td>
                                        <td style='width:23%;'>{{printData.collection_commission_from.payer}}</td>
                                        <td style='width:10%; font-weight:bold;'>Ship To:</td>
                                        <td style='width:23%;'>{{printData.collection_commission_from.ship_to}}</td>
                                    </tr>
                                    <tr>
                                        <td style='width:10%; font-weight:bold;'>Payment Term:</td>
                                        <td style='width:23%;'>{{printData.collection_commission_from.payment_term}}</td>
                                        <td style='width:10%; font-weight:bold;'>Billing Date:</td>
                                        <td style='width:23%;'>{{printData.collection_commission_from.billing_date}}</td>
                                        <td style='width:10%; font-weight:bold;'>Currency:</td>
                                        <td style='width:23%;'>{{printData.collection_commission_from.currency}}</td>
                                    </tr>

                                    <tr>
                                        <td style='width:10%; font-weight:bold;'>Commision Rate:</td>
                                        <td style='width:23%;'>{{printData.collection_commission_from.commission_rate_text}}</td>
                                        <td style='width:10%; font-weight:bold;'>Attention</td>
                                        <td style='width:23%;'>{{printData.collection_commission_from.attention_text}}</td>
                                        <td style='width:10%; font-weight:bold;'>Refference:</td>
                                        <td style='width:23%;'>{{printData.collection_commission_from.reference}}</td>
                                    </tr>

                                    <tr>
                                        <td style='width:10%; font-weight:bold;'>Description of Goods:</td>
                                        <td style='width:23%;'>{{printData.collection_commission_from.description}}</td>
                                    </tr>

                                    <tr>
                                        <td style='width:10%; font-weight:bold;'>Remark:</td>
                                        <td style='width:23%;'>{{printData.collection_commission_from.remark}}</td>
                                    </tr>
                        
                                </table>
                            </div>
                        </td>
                    </tr>
                    <td>
                        <div class="flex justify-start">
                            <table cellpadding='1' cellspacing='1' border='0' width='90%'
                                style='font: 11px sans-serif; border-collapse:collapse;'>
                                <tr style='font-weight:bold; text-align:center;'>
                                    <td style='border:1px solid black;'>Material Code</td>
                                    <td style='border:1px solid black;'>Material Description</td>
                                    <td style='border:1px solid black;'>Quantity </td>
                                    <td style='border:1px solid black;'>Unit </td>
                                    <td style='border:1px solid black;'>Price / Unit </td>
                                    <td style='border:1px solid black;'>Sale Turnover</td>
                                    <td style='border:1px solid black;'>Sale Turnover Currency</td>
                                    <td style='border:1px solid black;'>Calculation Type</td>
                                    <td style='border:1px solid black;'>Commission Rate</td>
                                    <td style='border:1px solid black;'>Commission Rate Currency</td>
                                    <td style='border:1px solid black;'>Total Amount</td>
                                    <td style='border:1px solid black;'>Remark</td>
                                </tr>
                                @for (item of printData.collection_commission_from.materials; track $index) {
                                    <tr>
                                        <td style='border:1px solid black;'>{{item.material_code}}</td>
                                        <td style='border:1px solid black;'>{{item.material_desc}}</td>
                                        <td style='border:1px solid black;'>{{item.quantity}}</td>
                                        <td style='border:1px solid black;'>{{item.unit_code}}</td>
                                        <td style='border:1px solid black;'>{{item.price_per_unit}}</td>
                                        <td style='border:1px solid black;'>{{item.sale_turn}}</td>
                                        <td style='border:1px solid black;'>{{item.currency}}</td>
                                        <td style='border:1px solid black;'>{{item.exp_cal_type}}</td>
                                        <td style='border:1px solid black;'>{{item.commission_rate}}</td>
                                        <td style='border:1px solid black;'>{{item.currency}}</td>
                                        <td style='border:1px solid black;'>{{item.total_amout}}</td>
                                        <td style='border:1px solid black;'>{{item.remark}}</td>
                                    </tr>
                                    }
                            </table>
                        </div>
                    </td>
                    }
                    @else {
                    <tr>
                        <td>-</td>
                    </tr>
                    }

                    <tr style='font-weight:bold;'>
                        <td style='border-bottom:2px solid black; text-align:left; font-size:13px;'>Collection Commission To</td>
                    </tr>
                    @if (printData.collection_commission_to) {
                        @if (printData.collection_commission_to.scgt_commission_details) {
                        <tr>
                            <td>
                                <div class="flex justify-left">
                                    <table cellpadding='1' cellspacing='1' border='0' width='90%'
                                        style='font: 11px sans-serif; border-collapse:collapse;'>
                                        <tr style='font-weight:bold; text-align:center;'>
                                            <td style='border:1px solid black;'>Commission To SCG INTL Company</td>
                                            <td style='border:1px solid black;'>Rep. Office</td>
                                            <td style='border:1px solid black;'>Calculation Type </td>
                                            <td style='border:1px solid black;'>Amount </td>
                                            <td style='border:1px solid black;'>Currency</td>
                                            <td style='border:1px solid black;'>Total Amount</td>
                                        </tr>
                                        @for (item of printData.collection_commission_to.scgt_commission_details; track $index) {
                                            <tr>
                                                <td style='border:1px solid black;'>{{item.vendor}}</td>
                                                <td style='border:1px solid black;'>{{item.rep_office}}</td>
                                                <td style='border:1px solid black;'>{{item.exp_cal_type}}</td>
                                                <td style='border:1px solid black;'>{{item.amount}}</td>
                                                <td style='border:1px solid black;'>{{item.amount_curency}}</td>
                                                <td style='border:1px solid black;'>{{item.total_amount}}</td>
                                            </tr>
                                            }
                                    </table>
                                </div>
                            </td>
                        </tr>
                        }
                        @if (printData.collection_commission_to.other_commission_details) {
                            <tr>
                                <td>
                                    <div class="flex justify-left">
                                        <table cellpadding='1' cellspacing='1' border='0' width='90%'
                                            style='font: 11px sans-serif; border-collapse:collapse;'>
                                            <tr style='font-weight:bold; text-align:center;'>
                                                <td style='border:1px solid black;'>Commission To Other</td>
                                                <td style='border:1px solid black;'>Calculation Type </td>
                                                <td style='border:1px solid black;'>Amount </td>
                                                <td style='border:1px solid black;'>Currency</td>
                                                <td style='border:1px solid black;'>Total Amount</td>
                                            </tr>
                                            @for (item of printData.collection_commission_to.other_commission_details; track $index) {
                                                <tr>
                                                    <td style='border:1px solid black;'>{{item.vendor}}</td>
                                                    <td style='border:1px solid black;'>{{item.exp_cal_type}}</td>
                                                    <td style='border:1px solid black;'>{{item.amount}}</td>
                                                    <td style='border:1px solid black;'>{{item.amount_curency}}</td>
                                                    <td style='border:1px solid black;'>{{item.total_amount}}</td>
                                                </tr>
                                                }
                                        </table>
                                    </div>
                                </td>
                            </tr>
                            }
                        }
                        @else {
                        <tr>
                            <td>-</td>
                        </tr>
                        }
                <tr style='font-weight:bold;'>
                    <td style='border-bottom:2px solid black; text-align:left; font-size:13px;'>Document Required</td>
                </tr>
                <tr>
                    <td style='width:10%; font:11px sans-serif; font-weight:bold;'>Document Required</td>
                </tr>
                @if (!printData.document || checkEmptyObject(printData.document) || !printData.document.document_required || printData.document.document_required.length === 0) {
                <tr>
                    <td>-</td>
                </tr>
                }
                @else {
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='100%'
                                style='font: 11px sans-serif; border-collapse:collapse;'>
                                <tr style='font-weight:bold; text-align:center;'>
                                    <td style='border:1px solid black;'>Document Name</td>
                                    <td style='border:1px solid black;'>Document from Supplier/<br />Document to
                                        Customer</td>
                                    <td style='border:1px solid black;'>Original</td>
                                    <td style='border:1px solid black;'>Copy</td>
                                    <td style='border:1px solid black;'>Remark</td>
                                </tr>
                                @for (item of printData.document.document_required; track $index) {
                                <tr>
                                    <td style='border:1px solid black;'>{{item.document_name}}</td>
                                    <td style='border:1px solid black;'>{{item.document_from}}</td>
                                    <td style='text-align:right; border:1px solid black;'>{{item.original}}</td>
                                    <td style='text-align:right; border:1px solid black;'>{{item.copy}}</td>
                                    <td style='border:1px solid black;'>{{item.remark}}</td>
                                </tr>
                                }
                            </table>
                        </div>
                    </td>
                </tr>
                }
                <tr style='padding-top:8px; font:11px sans-serif; font-weight:bold;'>
                    <td>Special Instruction for Operation Team <span style='color:red; font-weight:none;'>*Information
                            in REMARK is for communicate to operation team</span></td>
                </tr>
                <tr>
                    <td>{{printData.document ? printData.document.special_instruction : '-'}}</td>
                </tr>
                <tr>
                    <td style='padding-top:8px; width:10%; font:11px sans-serif; font-weight:bold;'>Document Send To
                    </td>
                </tr>
                @if (!printData.document || checkEmptyObject(printData.document) || !printData.document.doc_send_to || printData.document.doc_send_to.length === 0) {
                <tr>
                    <td>-</td>
                </tr>
                }
                @else {
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='100%'
                                style='font: 11px sans-serif; border-collapse:collapse;'>
                                <tr style='font-weight:bold; text-align:center;'>
                                    <td style='border:1px solid black;'>Document Send To</td>
                                    <td style='border:1px solid black;'>Company Name</td>
                                    <td style='border:1px solid black;'>Attention</td>
                                    <td style='border:1px solid black;'>Address</td>
                                    <td style='border:1px solid black;'>Country</td>
                                    <td style='border:1px solid black;'>Telephone</td>
                                    <td style='border:1px solid black;'>Fax</td>
                                    <td style='border:1px solid black;'>Remark</td>
                                </tr>
                                @for (item of printData.document.doc_send_to; track $index) {
                                <tr>
                                    <td style='border:1px solid black;'>{{item.send_to_name}}</td>
                                    <td style='border:1px solid black;'>{{item.company_name}}</td>
                                    <td style='border:1px solid black;'>{{item.attention}}</td>
                                    <td style='border:1px solid black;'>{{item.address}}</td>
                                    <td style='border:1px solid black;'>{{item.country}}</td>
                                    <td style='border:1px solid black;'>{{item.tel}}</td>
                                    <td style='border:1px solid black;'>{{item.fax}}</td>
                                    <td style='border:1px solid black;'>{{item.remark}}</td>
                                </tr>
                                }
                            </table>
                        </div>
                    </td>
                </tr>
                }
                <tr>
                    <td style='padding-top:8px; width:10%; font:11px sans-serif; font-weight:bold;'>
                        Document Send To Shipping's Contact Detail at Origin (For EXW, Border Only)
                    </td>
                </tr>
                @if (!printData.document || checkEmptyObject(printData.document) || !printData.document.doc_send_to_ship || printData.document.doc_send_to_ship.shipment_company
                === '') {
                <tr>
                    <td>-</td>
                </tr>
                }
                @else {
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='100%'
                                style='font: 11px sans-serif; border-collapse:collapse;'>
                                <tr style='font-weight:bold; text-align:center;'>
                                    <td style='border:1px solid black;'>Shipping Company</td>
                                    <td style='border:1px solid black;'>Attention</td>
                                    <td style='border:1px solid black;'>Telephone</td>
                                    <td style='border:1px solid black;'>Fax</td>
                                    <td style='border:1px solid black;'>Email</td>
                                </tr>
                                <tr>
                                    <td style='border:1px solid black;'>
                                        {{printData.document.doc_send_to_ship.shipment_company}}</td>
                                    <td style='border:1px solid black;'>
                                        {{printData.document.doc_send_to_ship.attention}}</td>
                                    <td style='border:1px solid black;'>{{printData.document.doc_send_to_ship.tel}}</td>
                                    <td style='border:1px solid black;'>{{printData.document.doc_send_to_ship.fax}}</td>
                                    <td style='border:1px solid black;'>{{printData.document.doc_send_to_ship.email}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
                }
                <tr>
                    <td style='padding-top:8px; width:10%; font:11px sans-serif; font-weight:bold;'>
                        BL Instruction (For Export Practice)
                    </td>
                </tr>
                @if (printData.document && printData.document.bl_instruction) {
                <tr>
                    <td>
                        <div class="flex justify-center">
                            <table cellpadding='1' cellspacing='1' border='0' width='100%'
                                style='font: 11px sans-serif; border-collapse:collapse;'>
                                <tr style='font-weight:bold; text-align:center;'>
                                    <td style='border:1px solid black;'>Shipper</td>
                                    <td style='border:1px solid black;'>Consignee</td>
                                    <td style='border:1px solid black;'>Notify Party</td>
                                </tr>
                                <tr>
                                    <td style='border:1px solid black;'>{{printData.document.bl_instruction.shipper}}
                                    </td>
                                    <td style='border:1px solid black;'>{{printData.document.bl_instruction.consignee}}
                                    </td>
                                    <td style='border:1px solid black;'>
                                        {{printData.document.bl_instruction.notify_party}}</td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
                }
                @else {
                <tr>
                    <td>-</td>
                </tr>
                }

                <tr style='font-weight:bold;'>
                    <td style='border-bottom:2px solid black; text-align:left; font-size:13px;'>Other Attachment</td>
                </tr>
                @if (printData.other_attachmant) {
                    <tr>
                        <td>
                            <div class="flex justify-center">
                                <table cellpadding='1' cellspacing='1' border='0' width='99%'
                                    style='font: 11px sans-serif; vertical-align:top;'>
                                    @for (item of printData.other_attachmant; track $index) {
                                    <tr>
                                        <td style='width:10%; font-weight:bold;'>Document:</td>
                                        <td style='width:23%;'>{{item.attachment_desc}}</td>
                                        <td style='width:10%; font-weight:bold;'>File Name:</td>
                                        <td style='width:23%;'>{{item.attachment_name}}</td>
                                    </tr>
                                    }
                                </table>
                            </div>
                        </td>
                    </tr>
                    }
                    @else {
                    <tr>
                        <td>-</td>
                    </tr>
                    }

            </table>
        </div>
    </div>
</div>