<div class="preview-print">
    <div class="page-header">
        <div class="flex justify-end">
            <img src="/assets/img/logo/logo-scgintl-sm.png" height="50px">
        </div>
        <div class="flex flex-col">
            <div class="text-center font-bold text-lg">COMMISSION AGREEMENT</div>
            <div class="text-center font-bold text-lg">No.4002003055</div>
        </div>
        <div class="flex justify-between">
            <div><span class="font-bold">Page: </span><span class="pageNum"></span> / {{totalPages}}</div>
            <div><span class="font-bold">Date: </span> {{now | date: 'dd MMMM yyyy'}}</div>
        </div>
        <div class="grid grid-cols-6 gap-2 w-full">
            <span class="font-bold">To: </span>
            <div class="col-span-5">
                SCG TRADING (CAMBODIA) CO., LTD.<br>
                Maybank Tower, No.43, Floor4, Preah Norodom Blvd.,<br>
                Sangkat Phsar Thmey3, Khan Daun Pen Phnom Penh, Cambodia Cambodia<br>
            </div>
        </div>
    </div>
    <table class="w-full page-body">
        <thead>
            <tr>
                <td>
                    <!--place holder for the fixed-position header-->
                    <div class="page-header-space"></div>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <div class="page-data-detail">
                        <div class="grid grid-cols-6 gap-2 w-full">
                            <span class="font-bold">Attention: </span>
                            <span class="col-span-2">K.ABC</span>
                            <div class="col-span-3 flex justify-end">
                                <span class="font-bold">Reference number:</span>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;Q2O-0820-2402-00043</span>
                            </div>
                        </div>
                        <div class="grid grid-cols-6 gap-2 w-full">
                            <span class="font-bold">Your Reference: </span>
                            <span class="col-span-5">Ref. test</span>
                        </div>
                        <div class="grid grid-cols-6 gap-2 w-full">
                            <span class="font-bold">Subject: </span>
                            <span class="col-span-5">Commission</span>
                        </div>
                        <div class="grid grid-cols-6 gap-2 w-full">
                            <span class="font-bold">Goods/Commomdities: </span>
                            <span class="col-span-5"></span>
                        </div>
                        <div class="grid grid-cols-6 gap-2 w-full">
                            <span class="font-bold"></span>
                            <span class="col-span-5">Commission</span>
                        </div>
                        <div class="detail mt-2">
                            <table class="w-full table-auto">
                                <thead>
                                    <tr>
                                        <th class="text-left" style="width: 50px;">Item</th>
                                        <th class="text-left">Description</th>
                                        <th class="text-left" style="width: 1%;">Amont (USD)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>001</td>
                                        <td class="whitespace-pre-line">{{'Commission\nCommission'}}</td>
                                        <td class="text-right">{{50 | number: '1.2-2'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td class="whitespace-pre-line"></td>
                                        <td class="text-right">{{50 | number: '1.2-2'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>This is to certify that, <span class="font-bold">{{'SCG TRADING (CAMBODIA) CO., LTD.'}}</span></div>
                        <div>will provide the commission of the cargo(es) pursuant to above-mentioned.</div>
                        <div>Contract to <span class="font-bold">{{'SCG International Corporation Co., Ltd.'}}</span> according to the following detail:</div>
                        <div class="grid grid-cols-6 gap-2 w-full">
                            <span class="font-bold col-span-2">- Commission calculation basis: </span>
                            <span class="col-span-4">{{'Test email commission'}}</span>
                        </div>
                        <div class="grid grid-cols-6 gap-2 w-full">
                            <span class="font-bold col-span-2">- Payment Terms: </span>
                            <span class="col-span-4">{{'Credit 30 Days after receive bill'}}</span>
                        </div>
                        <div class="grid grid-cols-6 gap-2 w-full">
                            <span class="font-bold col-span-2">- Your invoice: </span>
                            <span class="col-span-4">{{'5 USD/MT'}}</span>
                        </div>
                        <div class="w-full" style="bottom: 0; position: absolute;">
                            <div class="grid grid-cols-2 gap-2 w-full">
                                <div class="flex justify-center">
                                    <div>
                                        <div>For and on behalf of</div>
                                        <div>{{'SCG International Corporation Co., Ltd.'}}</div>
                                        <div class="for-sign"></div>
                                        <div>(Authorized Signature)</div>
                                    </div>
                                </div>
                                <div class="flex justify-center">
                                    <div>
                                        <div>Accepted and agreed by</div>
                                        <div>{{'SCG TRADING (COMBODIA) CO., LTD.'}}</div>
                                        <div class="for-sign"></div>
                                        <div>(Authorized Signature)</div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 font-bold">Payment Instruction:</div>
                            <div class="ml-5 w-full">
                                <div class="grid grid-cols-6 gap-2 w-full">
                                    <span class="font-bold">Beneficiary: </span>
                                    <span class="col-span-5">SCG International Corporation Co., Ltd.</span>
                                </div>
                                <div class="grid grid-cols-6 gap-2 w-full">
                                    <span class="font-bold">Bank Name: </span>
                                    <span class="col-span-5">THE SIAM COMMERCIAL BANK PCL.</span>
                                </div>
                                <div class="grid grid-cols-6 gap-2 w-full">
                                    <span class="font-bold">Branch: </span>
                                    <span class="col-span-5">RUTCHAYOTHIN</span>
                                </div>
                                <div class="grid grid-cols-6 gap-2 w-full">
                                    <span class="font-bold">Account No.: </span>
                                    <span class="col-span-5">111-3-00163-8</span>
                                </div>
                                <div class="grid grid-cols-6 gap-2 w-full">
                                    <span class="font-bold">Swift Code: </span>
                                    <span class="col-span-5">SICOTHEK</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td>
                    <!--place holder for the fixed-position footer-->
                    <div class="page-footer-space"></div>
                </td>
            </tr>
        </tfoot>
    </table>
    <div class="page-footer">
        <div class="grid grid-cols-11 gap-2 w-full">
            <div class="col-span-3 flex justify-center">
                <img src="/assets/img/logo/logo-footer-print.png" height="50px">
            </div>
            <div class="col-span-4">
                <div>SCG International Corporation Co., Ltd.</div>
                <div>1 Siam Cement Road,</div>
                <div>Bangsue, Bangkok 10800 Thailand</div>
                <div>Tel: +66-2586-2222 Fax: +66 2586 2251</div>
                <div>Website: www.scginternational.com</div>
            </div>
            <div class="col-span-4">
                <div>บริษัท เอสซีจี อินเตอร์เนชั่นแนล คอร์ปอเรชั่น จำกัด</div>
                <div>เลขที่ 1 ถนนปูนซิเมนต์ไทย แขวงบางซื่อ</div>
                <div>เขตบางซื่อ กรุงเทพมหานคร 10800 ประเทศไทย</div>
                <div>โทรศัพท์: +66-2586-2222 โทรสาร: +66 2586 2251</div>
                <div>เว็ปไซต์: www.scginternational.com</div>
            </div>
        </div>
    </div>
</div>