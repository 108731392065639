import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'app-print-download-preview',
  templateUrl: './print-download-preview.component.html',
  styleUrl: './print-download-preview.component.scss'
})
export class PrintDownloadPreviewComponent implements AfterViewInit {
  now = new Date();
  totalPages: number = 0;

  constructor() {
    let _this = this;
    window.addEventListener(
      "message",
      function (event) {
        if (typeof event.data == "object" && event.data.call == "sendCommissionFromPrintDownloadData") {
          console.log(event.data.value);
          _this.addPageNumber();
          window.parent.postMessage("commissionFromPrintDownloadDataPrepared", "*");
        }
      },
      false,
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.addPageNumber();
    });
  }

  private addPageNumber() {
    this.totalPages = Math.ceil(document.body.scrollHeight / 1123);
  }

}
