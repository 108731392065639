import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { GetPrintPreviewResponse } from '../../../../../../../../api/q2o/request/models/print-preview.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-print-preview-re-confirm',
  templateUrl: './print-preview-re-confirm.component.html',
  styleUrl: './print-preview-re-confirm.component.scss'
})
export class PrintPreviewReConfirmComponent implements AfterViewInit {
  printData!: GetPrintPreviewResponse;

  @ViewChild('page') page!: ElementRef;
  @ViewChild('containerPrint') containerPrint!: ElementRef;

  constructor(private titleService:Title) {
    let _this = this;
    window.addEventListener(
      "message",
      function (event) {
        if (typeof event.data == "object" && event.data.call == "sendPrintData") {
          _this.printData = event.data.value;
          for (let stockdata of _this.printData.materail.from_stock){
            let batchtext = ""
            for(let batchdata of stockdata.batchs){
              batchtext  += batchdata.ref_po_no + " "
            }
            stockdata.batchtext = batchtext
          }
          _this.titleService.setTitle(`Print Preview from Request No. : ${_this.printData.general_Info.request_no}`);
          setTimeout(() => {
            let page = document.getElementById('page');
            let containerPrint = document.getElementById('containerPrint');
            if (page && containerPrint) {
              let scale = (containerPrint.offsetWidth / page.offsetWidth).toFixed(2);
              let body = document.body as any;
              body.style.zoom = (Math.round(+scale / 0.005) * 0.005) - 0.01;
            }
          });
          window.parent.postMessage("dataPrepared", "*");
        }
        else if (typeof event.data == "object" && event.data.call == "sendPrintAction") {
          // console.log(event.data.value);
          // window.parent.postMessage("dataPrepared", "*");
          let body = document.body as any;
          const tmpZoom = body.style.zoom;
          body.style.zoom = 1;
          window.print();
          body.style.zoom = tmpZoom;
        }
        else if (typeof event.data == "object" && event.data.call == "sendPreviewData") {
          _this.printData = event.data.value;
          for (let stockdata of _this.printData.materail.from_stock){
            let batchtext = ""
            for(let batchdata of stockdata.batchs){
              batchtext  += batchdata.ref_po_no + " "
            }
            stockdata.batchtext = batchtext
          }
          _this.titleService.setTitle(`Print Preview from Request No. : ${_this.printData.general_Info.request_no}`);
          window.parent.postMessage("previewDataPrepared", "*");
        }
        else if (typeof event.data == "object" && event.data.call == "sendActionZoom") {
          let body = document.body as any;
          if (event.data.value === 'in') {
            body.style.zoom = +body.style.zoom + 0.25;
          }
          else {
            body.style.zoom = +body.style.zoom - 0.25;
          }
        }
      },
      false,
    );
  }

  ngAfterViewInit() {
    window.parent.postMessage("viewPrepared", "*");
  }

  trimEndDash(input: string) {
    return input.replace(/^\-+|\-+$/g, '');
  }

  checkEmptyObject(input: any) {
    return Object.keys(input).length === 0;
  }
}
